import { isEmpty } from 'lodash';

const translateLevel = level => {
  switch (level) {
    case 'basic':
      return 'בסיסית';
    case 'medium':
      return 'בינונית';
    case 'advanced':
      return 'מתקדם';
    default:
      return 'בסיסי';
  }
};

export const formatLearningHistoryDetails = (enrollment: any) => {
  if (isEmpty(enrollment)) return [];
  return {
    enrollment_total_lessons: enrollment.total_lessons,
    enrollment_total_subjects: enrollment.total_subjects,
    enrollment_total_progress: enrollment.total_progress,
    enrollment_total_exercises: enrollment.total_exercises,
    course_total_lessons: enrollment.course.total_lessons,
    course_total_subjects: enrollment.course.total_subjects,
    course_total_exercises: enrollment.course.total_exercises,
    lessons: enrollment.summary
      .filter(summary => summary?.lesson?.published === true)
      .map(summary => {
        //FIXME: remove all the "?" signs; It crashes the app on onboarding (when there is no enrollment)
        return {
          id: summary?.lesson?.id,
          title: summary?.lesson?.title,
          level: translateLevel(summary?.level),
          progress: summary?.progress,
          coins: summary?.coins,
          sections: summary?.sections,
          locked: summary?.locked,
          unlock_at: summary?.unlock_at,
        };
      }),
  };
};
