<template>
  <BaseWidget>
    <template #title>
      <div>תירגול מעשי</div>
    </template>
    <template #default>
      <div
        v-for="({ stage, locked }, index) in enrollment?.summary"
        :key="stage.id"
        class="flex items-center py-1 w-36 gap-4 text-sm">
        <div
          class="p-2 rounded-full mb-1"
          :class="{
            'cursor-default bg-primary-200': !locked,
            'bg-secondary-300': locked,
          }">
          <Unlock
            v-if="!locked"
            class="h-3 w-3 fill-primary-200 stroke-primary"/>
          <Lock
            v-else
            class="h-3 w-3 fill-secondary-300 stroke-secondary-500"/>
        </div>
        <div
          :class="{ 'cursor-pointer': !locked }"
          @click="!locked && goToProject(enrollment.project, stage.id)">
          <div class="whitespace-nowrap text-sm font-simplerRegular">
            שלב {{ index + 1 }}
          </div>
          <div
            title="title"
            class="whitespace-nowrap text-xs font-simplerLight leading-1 h-5">
            {{ stage.title }}
          </div>
        </div>
      </div>
    </template>
  </BaseWidget>
</template>

<script setup lang="ts">
import { BaseWidget } from '@nwire/amit-design-system';
import { Lock, Unlock } from 'lucide-vue-next';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

defineProps({
  enrollment: {
    type: Object,
    required: true,
  },
});

const goToProject = (project, stage) => {
  router.push({
    name: 'skill-project',
    params: { project, stage },
    query: { course: route.params.course },
  });
};
</script>
